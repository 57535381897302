import { FaFacebookF } from 'react-icons/fa';
import { BsYoutube } from 'react-icons/bs';
import { AiFillInstagram } from 'react-icons/ai';
import { RiWhatsappFill } from 'react-icons/ri';

export default function SocialMedia({ iconClass }) {
	return (
		<>
			<a href='https://www.facebook.com/axel.maisenhelder.9' target='_blank' rel='noreferrer'>
				<FaFacebookF className={`${iconClass} text-[#4267B2]`} />
			</a>
			<a
				href='https://www.youtube.com/channel/UC8UBAXlQf4ecSdyK5QV26BA/videos?view_as=subscriber'
				target='_blank'
				rel='noreferrer'>
				<BsYoutube className={`${iconClass} text-[#FF0000]`} />
			</a>
			<a href='https://www.instagram.com/architektur_heilbronn/' target='_blank' rel='noreferrer'>
				<AiFillInstagram className={`${iconClass} text-[#d62976]`} />
			</a>
			<a
				href='https://api.whatsapp.com/send?phone=491711291188&text=Hallo!'
				target='_blank'
				rel='noreferrer'>
				<RiWhatsappFill className={`${iconClass} text-[#25D366]`} />
			</a>
		</>
	);
}
