import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { IKImage, IKContext } from 'imagekitio-react';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function DialogPicture({ open, setOpen, image }) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<IKContext urlEndpoint='https://ik.imagekit.io/d6wyu834t9'>
				<Dialog maxWidth='xl' open={open} onClose={handleClose} TransitionComponent={Transition}>
					<AppBar
						sx={{ position: 'relative' }}
						style={{ background: 'white', position: 'sticky', top: 0, height: '0px' }}>
						<Toolbar>
							<CloseIcon
								onClick={handleClose}
								className='w-8 h-8 text-black bg-white rounded-full cursor-pointer hover:text-red-700 absolute right-3'
							/>
						</Toolbar>
					</AppBar>
					<div className=''>
						{/* <img src={image} className='object-cover' alt={image} /> */}
						<IKImage
							src={image}
							transformation={[
								{
									height: '200',
									width: '200',
								},
							]}
							className='object-cover'
							// src={urlFor(ig)}
							alt={image.name}
							// loading='lazy'
						/>
					</div>
				</Dialog>
			</IKContext>
		</div>
	);
}
