import Headline from '../components/Headline';

export default function Textbaustein() {
	return (
		<div className='mt-10 font-darkerGrotesque' id='maisenhelder'>
			<div className='mb-5'>
				<Headline title='Das Bewährte wieder tun!' />
			</div>
			<div className='max-w-4xl mb-10 mr-5 ml-5 sm:mx-auto' id='maisenhelder'>
				{/* <h1 className='text-2xl font-bold text-center'>Das Bewährte wieder tun!</h1> */}
				<span className='text-xl'>
					Die Wechselwirkung von Mensch, Architektur & Immobilien ist vielseitig interpretierbar. Wir
					verstehen darunter, dass die von uns neu zu gestaltenden Lebens- bzw. Arbeitsräume und deren
					Herstellung folgenden Kriterien entsprechen:
				</span>
				<ul className='list-disc ml-10 mt-3 text-xl'>
					<li>Kostentransparenz und Kostensicherheit für Ihr Bauvorhaben von Anfang an</li>
					<li>Der Kunde ist "König"</li>
					<li>Architektur in Qualität, Gestaltung und Umsetzung</li>
					<li>Einbindung von neusten Technologien</li>
					<li>Die ästhetischen Wünsche der Bauherren/-innen werden erfüllt</li>
				</ul>
				<hr className='mt-10' />
			</div>
		</div>
	);
}
