import React from 'react';

function Headline({ title }) {
	return (
		<>
			<h1 className='text-center text-3xl font-bold uppercase font-darkerGrotesque'>{title}</h1>
			<hr className='max-w-4xl mx-auto my-1' />
		</>
	);
}

export default Headline;
