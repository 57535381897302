export default function FormInputs({ form, handleFormChange }) {
	return (
		<div className='flex justify-start flex-col space-y-4'>
			<input
				id='contact-form-name'
				className='appearance-none border-2 border-gray-200 rounded w-full p-2 text-gray-700 focus:outline-none'
				type='text'
				value={form.name}
				name='name'
				onChange={handleFormChange}
				placeholder='Name'
				required
			/>
			<input
				id='contact-form-email'
				className='appearance-none border-2 border-gray-200 rounded w-full p-2 text-gray-700 last:focus:outline-none'
				type='email'
				value={form.email}
				name='email'
				onChange={handleFormChange}
				placeholder='Email'
				required
			/>
			<textarea
				rows='5'
				id='contact-form-message'
				className='appearance-none border-2 border-gray-200 rounded w-ful p-2 text-gray-700 focus:outline-none'
				type='text'
				name='message'
				value={form.message}
				onChange={handleFormChange}
				placeholder='Ihre Nachricht ...'
				required
			/>
		</div>
	);
}
