import React, { useEffect, useState } from 'react';
import { client } from '../client';
import Headline from '../components/Headline';

// import { BsYoutube } from 'react-icons/bs';

function Videos() {
	const [yt, setYt] = useState([]);

	useEffect(() => {
		const query = '*[_type=="youtube"]';
		client.fetch(query).then(data => setYt(data));
	}, []);

	return (
		// <div className='w-screen h-auto' id='videos'>
		<div id='videos' className='mt-12'>
			<Headline title='Videos' />
			<div className='grid grid-cols-4 grid-flow-row'>
				{yt.map(y => (
					<div
						className='col-span-4 sm:col-span-2 lg:col-span-1 border-[1px] border-black m-3 p-0'
						key={y.videoId}>
						<iframe
							key={y.videoId}
							title={y.titel}
							className='w-full h-64 lazy'
							src={`https://www.youtube-nocookie.com/embed/${y.videoId}`}
							allowFullScreen
							frameBorder='0'></iframe>
						{/* <iframe
							width='560'
							height='315'
							src='https://www.youtube-nocookie.com/embed/GAjohL_703Q'
							title='YouTube video player'
							frameborder='0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowfullscreen></iframe> */}
						<h1 className='font-bold mt-1 text-center'>{y.titel}</h1>
						{/* <p className='text-slate-800'>{y.beschreibung}</p> */}
					</div>
				))}
			</div>
		</div>
	);
}

export default Videos;
