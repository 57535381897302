import React, { useState } from 'react';
import Datenschutz from './Imprint/Datenschutz';
import Impressum from './Imprint/Impressum';
import SocialMedia from './header/SocialMedia';

const iconClass = 'cursor-pointer hover:scale-125 h-5 w-5';

function Footer() {
	const [openImpressum, setOpenImpressum] = useState(false);
	const [openDatenschutz, setOpenDatenschutz] = useState(false);

	return (
		<div className='shadow-inner font-darkerGrotesque text-xl'>
			<div className='h-24 sm:h-20 max-w-7xl mx-auto flex flex-col sm:flex-row justify-end sm:justify-between items-center pb-5 sm:pb-0'>
				<div className='flex space-x-4 sm:ml-10 mb-2 sm:mb-0'>
					<SocialMedia iconClass={iconClass} />
				</div>
				<div className='min-w-xl sm:mr-10'>
					<span className='cursor-pointer hover:text-gray-400' onClick={() => setOpenImpressum(true)}>
						Impressum
					</span>{' '}
					| {''}
					<span className='cursor-pointer hover:text-gray-400' onClick={() => setOpenDatenschutz(true)}>
						Datenschutz
					</span>
				</div>
			</div>
			<Impressum open={openImpressum} setOpen={setOpenImpressum} />
			<Datenschutz open={openDatenschutz} setOpen={setOpenDatenschutz} />
		</div>
	);
}

export default Footer;
