export default function TextBauvorhaben() {
	const textDiv = 'border border-black w-full p-4 font-merriweather';
	const h1Div = 'text-2xl text-center font-bold mb-1';
	const pDiv = 'mt-2 text-center text-xl';
	return (
		<div className='flex flex-col md:flex-row justify-around gap-2 max-w-screen-8xl m-4'>
			<div className={textDiv}>
				<h1 className={h1Div}>Planung</h1>
				<hr />
				<p className={pDiv}>
					Wir bieten das gesamte Leistungsspektrum für Immobilienprojekte. Wir sind Ihre Experten und
					Ihre erfahrenen Partner für Architekten- und Ingenieureleistungen. Dies umfasst nicht nur den
					Neubau, wir projektieren und revitalisieren Denkmale bis hin zum Um- und Anbau von Scheunen
					oder alten Industrieanlagen.
				</p>
			</div>
			<div className={textDiv}>
				<h1 className={h1Div}>Betreuung</h1>
				<hr />
				<p className={pDiv}>
					Wir halten Sie ständig auf dem Laufenden und kümmern uns um Ihre Belange. Wir bemustern mit
					Ihnen Ihre Ausstattung und beraten Sie in der Auswahl Ihrer Materialien. Der Umgang mit
					Genehmigungsbehörden, die Erstellung von Finanzierungs- oder Prospektunterlagen sind ein
					selbstverständlicher Service für Sie und Ihre Immobilie.
				</p>
			</div>
			<div className={textDiv}>
				<h1 className={h1Div}>Umsetzung</h1>
				<hr />
				<p className={pDiv}>
					Erfahrung und Präsenz vor Ort ist bei der Bauleitung und Umsetzung Ihres Bauvorhabens durch
					nichts zu ersetzten. Unterstützt durch und mit modernster Technik haben Sie in uns den idealen
					Umsetzungspartner als Architekten mit mehr als 30 Jahren Berufserfahrung.
				</p>
			</div>
			<div className={textDiv}>
				<h1 className={h1Div}>Aufmaßservice</h1>
				<hr />
				<p className={pDiv}>
					<span className='font-bold'>Manchmal sind es Millimeter, die entscheiden!</span>
					<br />
					Egal, ob Küche, Bad, Wohnräume oder ganze Wohnflächenaufmaße für Denkmale – wir übernehmen die
					komplette Vermessung. Eine Drohne mit der neusten Lidar-Technologie erfasst Ihre
					Bestandsimmobilie von außen für weitere Planungsprozesse.
				</p>
			</div>
		</div>
	);
}
