import React, { useState, useEffect } from 'react';
import { urlFor, client } from '../client';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import Headline from '../components/Headline';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import DialogPicture from '../components/bauvorhaben/DialogPicture';
import { IKImage, IKContext } from 'imagekitio-react';

function AbgeschlosseneBauvorhaben() {
	const [images, setImages] = useState([]);
	const [currentImage, setCurrentImage] = useState('');
	const [openPicture, setOpenPicture] = useState(false);

	useEffect(() => {
		const query = '*[_type == "gallery2"]';
		client.fetch(query).then(data => setImages(data));
	}, []);

	function useWindowSize() {
		const [windowSize, setWindowSize] = useState({
			width: undefined,
			height: undefined,
		});
		useEffect(() => {
			// Handler to call on window resize
			function handleResize() {
				// Set window width/height to state
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}
			// Add event listener
			window.addEventListener('resize', handleResize);
			// Call handler right away so state gets updated with initial window size
			handleResize();
			// Remove event listener on cleanup
			return () => window.removeEventListener('resize', handleResize);
		}, []); // Empty array ensures that effect is only run on mount
		return windowSize;
	}

	const size = useWindowSize();

	const openLarge = image => {
		setCurrentImage(image);
		setOpenPicture(true);
	};

	return (
		<>
			<IKContext urlEndpoint='https://ik.imagekit.io/d6wyu834t9'>
				<div
					className='max-w-8xl mx-auto min-h-fit mt-12 font-darkerGrotesque'
					id='projekte-abgeschlossen'>
					<Headline title='Abgeschlossene Bauvorhaben' />
					{images.map(i => (
						<div className='relative' key={i.name}>
							<h1 className='text-white text-xl md:text-2xl lowercase absolute z-10 md:bottom-5 md:left-5 bottom-2 left-5 mix-blend-difference'>
								{i.name}
							</h1>
							<Swiper
								modules={[Navigation]}
								navigation
								// speed={800}
								slidesPerView={
									size.width < 450
										? 1
										: size.width >= 1000 && size.width < 1400
										? 4
										: size.width >= 1400
										? 6
										: 2
								}
								spaceBetween={5}
								loop
								className='h-52 w-full my-2 z-0'>
								{/* h-24 sm:h-32 md:h-64 */}
								{/* {imgs.map((i, index) => ( */}
								{i.imagesGallery.map(ig => (
									<SwiperSlide className='' key={ig._key}>
										{/* <img
										className='h-52 w-full object-cover grayscale hover:grayscale-0'
										src={urlFor(ig)}
										alt={i.name + '_' + ig._key}
										loading='lazy'
										onClick={() => openLarge(urlFor(ig))}
									/> */}
										<IKImage
											src={urlFor(ig)}
											transformation={{
												height: '200',
												width: '200',
											}}
											className='h-52 w-full object-cover grayscale hover:grayscale-0 cursor-pointer'
											// src={urlFor(ig)}
											alt={i.name + '_' + ig._key}
											loading='lazy'
											onClick={() => openLarge(urlFor(ig))}
										/>
										{/* <img className='object-cover h-64 w-full' src={i} alt={`slide-${index}`} loading='lazy' /> */}
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					))}
				</div>
				<DialogPicture open={openPicture} setOpen={setOpenPicture} image={currentImage} />
			</IKContext>
		</>
	);
}

export default AbgeschlosseneBauvorhaben;
