import sanitClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanitClient({
	projectId: process.env.REACT_APP_SANITY_ID,
	dataset: 'production',
	useCdn: true,
	token: process.env.REACT_APP_SANITY_TOKEN,
});

const builder = imageUrlBuilder(client);

export const urlFor = source => builder.image(source);
