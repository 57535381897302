import Headerlinks from './Headerlinks';
import SocialMedia from './SocialMedia';
import { AiOutlineMenu } from 'react-icons/ai';
import logo from '../../images/logo-am.png';

export default function Navbar({ handleNav, shadow }) {
	const handleScroll = id => {
		const element = document.getElementById(id);
		const headerOffset = 80;
		const elementPosition = element.getBoundingClientRect().top;
		const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

		window.scrollTo({
			top: offsetPosition,
			// behavior: 'smooth',
		});
	};
	const iconClass = 'hidden sm:flex cursor-pointer hover:scale-125 h-5 w-5';
	return (
		<div className='flex justify-between items-center w-full h-full px-2 2xl:px-16'>
			{/* <a href='#maisenhelder'> */}
			<span
				className='text-xl font-bold uppercase flex items-center cursor-pointer'
				onClick={() => handleScroll('maisenhelder')}>
				<img src={logo} alt='Logo' className='h-8 rounded-sm mr-3' />
				{/* <span className='text-gray-500 drop-shadow'>AXEL</span> */}
				<span className='drop-shadow'>AxelMaisenhelder</span>
			</span>
			{/* </a> */}
			<ul className='hidden xl:flex'>
				<Headerlinks
					stil={
						shadow
							? 'ml-8 text-sm uppercase hover:text-gray-400 cursor-pointer'
							: 'ml-8 text-sm uppercase hover:text-black cursor-pointer text-black'
					}
				/>
			</ul>
			<div className='flex space-x-4 absolute xl:relative right-14 xl:right-0'>
				<SocialMedia iconClass={iconClass} />
			</div>
			<div onClick={handleNav} className='xl:hidden cursor-pointer'>
				<AiOutlineMenu size={25} />
			</div>
		</div>
	);
}
