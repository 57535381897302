import { AiFillPhone } from 'react-icons/ai';
import { BsFillPhoneFill } from 'react-icons/bs';
import { FaFax } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { SiGooglemaps } from 'react-icons/si';
import { Link } from 'react-router-dom';

export default function KontaktDetails() {
	const iconClass = 'w-5 h-5 mr-3';
	return (
		<div className='flex flex-col font-merriweather text-xl'>
			<h1 className='text-2xl font-bold text-center lg:text-start mb-4'>Hier finden Sie uns</h1>
			<span className='font-bold'>Architektur & Immobilien</span>
			<span className='font-bold'>Axel Maisenhelder</span> Arch.Dipl.Ing.(FH)
			<br /> <span className=''>Römerstraße 19</span>
			<div className='flex items-center'>
				D-74223 Flein{' '}
				<a
					href='https://www.google.com/maps/place/Architektur+%26+Immobilien/@49.103531,9.2175951,15z/data=!4m2!3m1!1s0x0:0x8da1f6335c11aed?sa=X&ved=2ahUKEwiNpdGRjYf5AhVziv0HHRUbAagQ_BJ6BAg4EAU'
					target='_blank'
					rel='noreferrer'>
					<SiGooglemaps className='ml-2 hover:scale-105 cursor-pointer' />
				</a>
			</div>
			<br />
			<div className='flex items-center'>
				<BsFillPhoneFill className={iconClass} />
				+49 171 129 1188
			</div>
			<div className='flex items-center'>
				<AiFillPhone className={iconClass} /> +49 7131 618863-0
			</div>
			<div className='flex items-center'>
				<FaFax className={iconClass} /> +49 7131 618863-3
			</div>
			<div className='flex items-center'>
				<MdEmail className={iconClass} />{' '}
				<a href='mailto:architektur@maisenhelder.de' className='hover:text-gray-400'>
					architektur@maisenhelder.de
				</a>
			</div>
		</div>
	);
}
