import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { client } from '../client';
import { Navigation } from 'swiper';
import Headline from '../components/Headline';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

function Videos() {
	const [yt, setYt] = useState([]);

	useEffect(() => {
		const query = '*[_type=="youtube"]';
		client.fetch(query).then(data => setYt(data));
	}, []);

	function useWindowSize() {
		const [windowSize, setWindowSize] = useState({
			width: undefined,
			height: undefined,
		});
		useEffect(() => {
			// Handler to call on window resize
			function handleResize() {
				// Set window width/height to state
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}
			// Add event listener
			window.addEventListener('resize', handleResize);
			// Call handler right away so state gets updated with initial window size
			handleResize();
			// Remove event listener on cleanup
			return () => window.removeEventListener('resize', handleResize);
		}, []); // Empty array ensures that effect is only run on mount
		return windowSize;
	}

	const size = useWindowSize();

	return (
		<div className='relative max-w-8xl mx-auto min-h-fit mt-12' id='videos'>
			<Headline title='Videos' />
			<div className='relative'>
				<Swiper
					modules={[Navigation]}
					navigation
					slidesPerView={size.width < 450 ? 1 : size.width >= 1000 ? 4 : 2}
					// slidesPerView='1 md:2 lg:4'
					spaceBetween={5}
					loop
					className='my-2 z-0'>
					{yt.map(y => (
						<SwiperSlide className='select-none' key={y.videoId}>
							{/* <iframe
								key={y.videoId}
								title={y.titel}
								className='w-full h-64 lazy'
								src={`https://www.youtube-nocookie.com/embed/${y.videoId}&origin=https://maisenhelder.de`}
								allowFullScreen
								frameBorder='0'></iframe> */}
							<div className=''>
								<LiteYouTubeEmbed id={y.videoId} noCookie={true} title={y.title} iframeClass='' />
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
}

export default Videos;
