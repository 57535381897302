import { useState } from 'react';
import Header from './components/Header';
import AktuelleBauvorhaben from './content/AktuelleBauvorhaben';
import AbgeschlosseneBauvorhaben from './content/AbeschlosseneBauvorhaben';
import Kontakt from './content/Kontakt';
import Footer from './components/Footer';
import Youtube from './content/Youtube';
import Youtube2 from './content/Youtube2';
import ScrollBtn from './components/ScrollBtn';
import CookieConsent, {
	Cookies,
	getCookieConsentValue,
	resetCookieConsentValue,
} from 'react-cookie-consent';
import { useCookies } from 'react-cookie';
import Textbaustein from './content/Textbaustein';

const buttonClass = {
	display: 'none',
	position: 'fixed',
	bottom: '20px',
	right: '30px',
	zIndex: '99',
	border: 'none',
	outline: 'none',
	backgroundColor: 'red',
	color: 'white',
	cursor: 'pointer',
	padding: '15px',
	borderRadius: '10px',
	fontSize: '18px',
};

function App() {
	// const [displayButton, setDisplayButton] = useState('block');
	// const mybutton = document.getElementById('myBtn');

	// const handleScrollTop = () => {
	// 	document.body.scrollTop = 0; // For Safari
	// 	document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	// };
	const [cookies, setCookie] = useCookies();
	// console.log(cookies);

	return (
		<>
			<div className='overflow-x-hidden'>
				<Header />
				<AktuelleBauvorhaben />
				<AbgeschlosseneBauvorhaben />
				{/* <Youtube /> */}
				<Youtube2 />
				<Textbaustein />
				<Kontakt />
				<Footer />
				<CookieConsent
					location='bottom'
					buttonText='Akzeptieren'
					cookieName='cookieAccept'
					style={{ background: '#D3D3D3', color: '#000' }}
					buttonStyle={{ background: '#fff', color: '#000', fontSize: '13px' }}
					enableDeclineButton
					declineButtonText='Ablehnen'
					declineButtonStyle={{ background: '#000', color: '#fff' }}
					// onAccept={() => console.log(getCookieConsentValue())}
					// onDecline={() => console.log(Cookies)}
					expires={150}>
					Diese Website verwendet notwendige Cookies.{' '}
					{/* <span style={{ fontSize: '10px' }}>This bit of text is smaller :O</span> */}
				</CookieConsent>
			</div>
		</>
	);
}

export default App;
