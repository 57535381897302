import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { AiOutlineClose } from 'react-icons/ai';
import { Tooltip } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function Datenschutz({ open, setOpen }) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
				<AppBar
					sx={{ position: 'relative' }}
					style={{ background: '#fff', position: 'sticky', top: 0 }}>
					<Toolbar>
						<Tooltip title='Datenschutz schließen' placement='bottom-start'>
							<Typography
								sx={{ ml: 3, flex: 1 }}
								variant='h6'
								fontFamily='Darker Grotesque'
								component='div'
								color='black'
								className='cursor-pointer'
								onClick={() => setOpen(false)}>
								Datenschutz
							</Typography>
						</Tooltip>
						<AiOutlineClose
							onClick={handleClose}
							className='w-5 h-5 text-black cursor-pointer hover:text-gray-400  '
						/>
					</Toolbar>
				</AppBar>
				<List>
					<div style={{ margin: '50px 50px 50px 50px' }} className='font-darkerGrotesque text-xl'>
						Datenschutzerklärung von Architekturbüro: <br />
						Architektur & Immobilien, Axel Maisenhelder, Römerstrasse 19, D-74223 Flein
						<br />
						<br />
						{/* 1 */}
						<strong>1. Gegenstand dieser Datenschutzerklärung</strong>
						<br />
						Der Schutz Ihrer personenbezogenen Daten (im Folgenden kurz ,,Daten“) ist uns ein großes und
						sehr wichtiges Anliegen. Nachfolgend möchten wir Sie daher ausführlich darüber informieren,
						welche Daten bei uns erhoben und wie diese von uns im Folgenden verarbeitet oder genutzt
						werden, ebenso wie, welche begleitenden Schutzmaßnahmen wir auch in technischer und
						organisatorischer Hinsicht getroffen haben.
						<br />
						<br />
						<Divider />
						<br />
						<strong>2. Verantwortliche Stelle/Diensteanbieter</strong>
						<br />
						Verantwortlicher nach Art. 4 DSGVO und zugleich Diensteanbieter im Sinne des
						Telemediengesetzes (TMG) ist: <br />
						Axel Maisenhelder
						<br /> E-Mail: axel@maisenhelder.de <br />
						Adresse: Axel Maisenhelder, Römerstrasse 19, D-74223 Flein <br />
						Telefon: +49 7131 61 88 63-0
						<br />
						Telefax: +49 7131 61 88 63-3
						<br /> Axel Maisenhelder ist verantwortlich nach § 55 des Rundfunk-Staatsvertrags.
						<br />
						<br />
						<Divider />
						<br />
						<strong> 3. Erhebung und Verwendung Ihrer Daten</strong>
						<br />
						Alle personenbezogenen Daten, die wir von Ihnen erheben, werden wir nur zu dem angegebenen
						Zweck erheben, verarbeiten und nutzen. Dabei beachten wir, dass dies nur im Rahmen der jeweils
						geltenden Rechtsvorschriften bzw. ansonsten nur mit Ihrer Einwilligung geschieht. Nach der
						EU-Datenschutzgrundverordnung haben Sie jederzeit ein Recht auf unentgeltliche Auskunft über
						Ihre gespeicherten Daten sowie ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser
						Daten. Sie können gemäß Art. 21 DSGVO in den dort genannten Fällen Widerspruch gegen die
						Verarbeitung Ihrer Daten erheben. Bitte wenden Sie sich an "architektur@maisenhelder.de" oder
						senden Sie uns Ihre Verlangen per Post. Sie haben das Recht zur Beschwerde bei der zuständigen
						Aufsichtsbehörde für den Datenschutz: Der Landesbeauftragte für den Datenschutz und die
						Informationsfreiheit Königstrasse 10 a, 70173 Stuttgart Telefon: 0711/615541-0 Telefax:
						0711/615541-15 E-Mail: poststelle@lfdi.bwl.de www.baden-wuerttemberg.datenschutz.de/ Umfang
						und Art der Erhebung und Verwendung Ihrer Daten unterscheidet sich danach, ob Sie unseren
						Internetauftritt nur zum Abruf von Informationen besuchen oder von uns angebotene Leistungen
						in Anspruch nehmen: a) Internetnutzung Für die nur informatorische Nutzung unseres
						Internetauftritts ist es grundsätzlich nicht erforderlich, dass Sie personenbezogene Daten
						angeben. Vielmehr erheben und verwenden wir in diesem Fall nur diejenigen Ihrer Daten, die uns
						Ihr Internetbrowser automatisch übermittelt, wie etwa: • Datum und Uhrzeit des Abrufs einer
						unserer Internetseiten • Ihren Browsertyp • die Browser-Einstellungen • das verwendete
						Betriebssystem • die von Ihnen zuletzt besuchte Seite • die übertragene Datenmenge und der
						Zugriffsstatus (Datei übertragen, Datei nicht gefunden etc.) sowie • Ihre IP-Adresse. Diese
						Daten erheben und verwenden wir bei einem informatorischen Besuch ausschließlich in nicht-
						personenbezogener Form. Dies erfolgt, um die Nutzung der von Ihnen abgerufenen Internetseiten
						überhaupt zu ermöglichen, zu statistischen Zwecken sowie zur Verbesserung unseres
						Internetangebots. Die IP-Adresse speichern wir nur für die Dauer Ihres Besuchs, eine
						personenbezogene Auswertung findet nicht statt. Eine Zusammenführung dieser Daten mit anderen
						Datenquellen wird nicht vorgenommen, die Daten werden zudem nach einer statistischen
						Auswertung gelöscht. Dies ist nach der Entscheidung des Europäischen Gerichtshofs vom
						19.10.2016 (Az.: C 582/14) auch in Anbetracht des Telemediengesetzes zulässig. b) Nutzung von
						Angeboten bzw. Datenverwendung zur Aufgabenerfüllung Soweit Sie von uns angebotene Leistungen
						in Anspruch nehmen wollen, ist es möglicherweise nötig, dass Sie dazu weitere Daten angeben.
						Es handelt sich um diejenigen Daten, die zur jeweiligen Abwicklung erforderlich sind. Weitere
						Angaben können Sie ggf. freiwillig tätigen; sie sind von uns entsprechend als optional
						markiert. Ein Teil der Leistungen wird auch im Loginbereich der Homepage bereitgestellt. Die
						Erhebung oder Verwendung Ihrer Daten erfolgt zu dem Zweck, die von Ihnen gewünschte notwendige
						Leistung zu erbringen. Ihre Daten werden zu vorgenanntem Zweck ggf. an uns unterstützende
						Dienstleister weitergegeben, die wir selbstverständlich sorgfältig ausgewählt und zur
						Einhaltung des Datenschutzrechts verpflichtet haben. Dabei kann es sich insbesondere um
						technische Dienstleister oder Versanddienstleister (Lettershop etc.) handeln. Die Weitergabe
						Ihrer Daten an andere Dritte erfolgt ansonsten nur, wenn dies gesetzlich erlaubt ist oder wir
						Ihre explizite Einwilligung dafür erhalten haben.
						<br />
						<br />
						<Divider />
						<br />
						<strong>4. Datenschutzrechtliche Einwilligung</strong>
						<br />
						Über die Abwicklung der von Ihnen gewünschten Leistungen hinaus möchten wir gerne –
						selbstverständlich nur, soweit Sie darin an gesonderter Stelle explizit einwilligen – einen
						auf Ihre Interessen ausgerichteten Internetauftritt bieten sowie Ihnen gelegentlich per Post
						oder E-Mail (in Form unseres Newsletters) für Sie auf Basis Ihrer Daten interessante
						Neuigkeiten und Hinweise über uns zukommen lassen und Ihre Daten zur dazu notwendigen
						Auswertung sowie zu Marktforschungszwecken verwenden. Hierfür ist es technisch nötig, dass wir
						Ihre anfallenden sowie angegebenen Daten in Nutzungsprofilen zusammenfassen und diese zu
						vorgenannten Zwecken auswerten. Dies erfolgt lediglich intern und nur zu vorgenannten Zwecken.
						Für die Veröffentlichung von Bildmaterial, das Ihrem Urheberrecht unterliegt oder auf dem Sie
						abgebildet sind, benötigen wir unter Umständen ebenfalls Ihre Einwilligung. Ihre Einwilligung
						können Sie gesondert abgeben. Sie können sie im Anschluss jederzeit mit Wirkung für die
						Zukunft widerrufen. Die Einwilligung in Bezug auf Cookies bzw. Werbetracker richtet sich nach
						untenstehendem § 6 dieser Erklärung.
						<br />
						<br />
						<Divider />
						<br />
						<strong>5. Newsletter</strong>
						<br />
						Um sich bei unserem E-Mail-Newsletterdienst anmelden zu können, benötigen wir neben Ihrer
						datenschutzrechtlichen Einwilligung mindestens Ihre E-Mail-Adresse, an die der Newsletter
						versendet werden soll. Etwaige weitere Angaben sind freiwillig und werden verwendet, um Sie
						persönlich ansprechen und den Inhalt des Newsletters persönlich ausgestaltet zu können sowie
						Rückfragen zur E-Mail-Adresse klären zu können. Für den Newsletterversand verwenden wir in der
						Regel das sog. Double Opt-In-Verfahren, d.h. wir werden Ihnen erst dann den Newsletter
						zusenden, wenn Sie zuvor Ihre Anmeldung über eine Ihnen zu diesem Zweck zugesandte
						Bestätigungs-E-Mail per darin enthaltenem Link bestätigen. Damit möchten wir sicherstellen,
						dass nur Sie selbst sich als Inhaber der angegebenen E-Mail-Adresse zum Newsletter anmelden
						können. Ihre diesbezügliche Bestätigung muss zeitnah nach Erhalt der Bestätigungs-E-Mail
						erfolgen, da andernfalls Ihre Newsletter-Anmeldung automatisch aus unserer Datenbank gelöscht
						wird. Sie können einen von Ihnen bei uns abonnierten Newsletter jederzeit abbestellen. Dazu
						können Sie uns entweder eine formlose E-Mail an zusenden oder über den Link am Ende des
						Newsletters eine Stornierung vornehmen.
						<br />
						<br />
						<Divider />
						<br />
						<strong>6. Einsatz von Cookies</strong>
						<br />
						Für unseren Internetauftritt nutzen wir die Technik der Cookies. Cookies sind kleine
						Textdateien, die im Rahmen Ihres Besuchs unserer Internetseiten von unserem Webserver an Ihren
						Browser gesandt und von diesem auf Ihrem Rechner für einen späteren Abruf vorgehalten werden.
						Wir verwenden ebenfalls sogenannte Web Beacons (unsichtbare Grafiken). Durch diese Web Beacons
						können Informationen wie der Besuch auf unseren Webseiten ausgewertet werden. Die durch
						Cookies und Web Beacons erzeugten Informationen über die Benutzung dieser Website
						(einschließlich der IP- Adresse der Nutzer) und Auslieferung von Werbung können auch an Server
						von Google LLC in der Europäischen Union und den USA übertragen und dort gespeichert werden.
						(vgl. Nr. 8). Die weitergebenen Daten können nicht mit anderen von Ihnen gespeicherten Daten
						zusammengeführt werden. Ob Cookies gesetzt und abgerufen werden können, können Sie durch die
						Einstellungen in Ihrem Browser selbst bestimmen. Sie können in Ihrem Browser etwa das
						Speichern von Cookies gänzlich deaktivieren, es auf bestimmte Webseiten beschränken oder Ihren
						Browser so konfigurieren, dass er Sie automatisch benachrichtigt, sobald ein Cookie gesetzt
						werden soll und Sie um Rückmeldung dazu bittet. Für den vollen Funktionsumfang unseres
						Internetauftritts ist es allerdings aus technischen Gründen erforderlich, die genannten
						Session Cookies zuzulassen. Ab dem Inkrafttreten der Datenschutzgrundverordnung am 25.05.2018
						werden wir vor der Nutzung von Cookies Ihre explizite Zustimmung für die Nutzung von Cookies
						vor deren Verwendung einholen.
						<br />
						<br />
						<Divider />
						<br />
						<strong>7. Widerrufs- und Widerspruchsrecht</strong>
						<br />
						Wir weisen Sie darauf hin, dass Sie eine uns ggf. erteilte datenschutzrechtliche Einwilligung
						jederzeit mit Wirkung für die Zukunft widerrufen können. Soweit gesetzliche Anforderungen zur
						Erhebung von Daten bestehen (z.B. Architektenliste), besteht kein Widerrufsrecht.
						<br />
						<br />
						<Divider />
						<br />
						<strong>8. Einsatz von Statistiktools</strong>
						<br />
						Google Analytics Uns ist es wichtig, unsere Internetseiten möglichst optimal auszugestalten
						und damit für unsere Besucher attraktiv zu machen. Dazu ist es nötig, dass wir wissen, welche
						Teile davon wie bei unseren Besuchern ankommen. Unsere Webseite benutzt Google Analytics,
						einen Webanalysedienst der Google LLC (,,Google“). Google Analytics verwendet ebenfalls
						Cookies, also Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der
						Benutzung der Webseite durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen
						über Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA
						übertragen und dort gespeichert. Im Fall der Aktivierung der IP- Anonymisierung auf dieser
						Webseite wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen
						Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
						zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in
						den USA übertragen und dort gekürzt. In unserem Auftrag wird Google diese Informationen
						benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten
						zusammenzustellen und um weitere mit der Webseitennutzung und der Internetnutzung verbundene
						Dienstleistungen gegenüber uns als Webseitenbetreiber zu erbringen. Die im Rahmen von Google
						Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
						zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung
						Ihrer Browsersoftware verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
						ggf. nicht sämtliche Funktionen dieser Webseite vollumfänglich werden nutzen können. Sie
						können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
						Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
						Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare
						Browser-Plugin herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de
						<br />
						<br />
						<Divider />
						<br />
						<strong>9. Datenschutzerklärung für die Nutzung von Instagram</strong>
						<br />
						Auf unseren Seiten sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden
						angeboten durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA integriert.
						Wenn Sie in Ihrem Instagram - Account eingeloggt sind können Sie durch Anklicken des Instagram
						- Buttons die Inhalte unserer Seiten mit Ihrem Instagram - Profil verlinken. Dadurch kann
						Instagram den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass
						wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren
						Nutzung durch Instagram erhalten. Weitere Informationen hierzu finden Sie in der
						Datenschutzerklärung von Instagram: http://instagram.com/about/legal/privacy/
						<br />
						<br />
						<Divider />
						<br />
						<strong>10. Datenschutzerklärung für die Nutzung von facebook-plugins (like-button)</strong>
						<br />
						Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, Anbieter Facebook Inc., 1
						Hacker Way, Menlo Park, California 94025, USA, integriert. Die Facebook-Plugins erkennen Sie
						an dem Facebook-Logo oder dem "Like-Button" ("Gefällt mir") auf unserer Seite. Eine übersicht
						über die Facebook-Plugins finden Sie hier: http://developers.facebook.com/docs/plugins/. Wenn
						Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem
						Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie
						mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook "Like-Button" anklicken
						während Sie in Ihrem Facebook- Account eingeloggt sind, können Sie die Inhalte unserer Seiten
						auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch unserer Seiten Ihrem
						Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis
						vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere
						Informationen hierzu finden Sie in der Datenschutzerklärung von Facebook unter
						http://de-de.facebook.com/policy.php. Wenn Sie nicht wünschen, dass Facebook den Besuch
						unserer Seiten Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem
						Facebook-Benutzerkonto aus.
						<br />
						<br />
						<Divider />
						<br />
						<strong>11. Datenschutzerklärung für die Nutzung von Twitter</strong>
						<br />
						Auf unseren Seiten sind Funktionen des Dienstes Twitter eingebunden. Diese Funktionen werden
						angeboten durch die Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA.
						Durch das Benutzen von Twitter und der Funktion "Re-Tweet" werden die von Ihnen besuchten
						Webseiten mit Ihrem Twitter-Account verknüpft und anderen Nutzern bekannt gegeben. Dabei
						werden auch Daten an Twitter übertragen. Wir weisen darauf hin, dass wir als Anbieter der
						Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Twitter
						erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Twitter
						unter http://twitter.com/privacy. Ihre Datenschutzeinstellungen bei Twitter können Sie in den
						Konto-Einstellungen unter http://twitter.com/account/ settings ändern.
						<br />
						<br />
						<Divider />
						<br />
						<strong>12. EINBINDUNG VON YOUTUBE-VIDEOS</strong>
						<br />
						WIR HABEN YOUTUBE-VIDEOS IN UNSER ONLINE-ANGEBOT EINGEBUNDEN, DIE AUF WWW.YOUTUBE.COM
						GESPEICHERT SIND UND VON UNSERER WEBSITE AUS DIREKT ABSPIELBAR SIND. DIESE SIND ALLE IM
						„ERWEITERTEN DATENSCHUTZ-MODUS“ EINGEBUNDEN, D.H. DASS KEINE DATEN ÜBER SIE ALS NUTZER AN
						YOUTUBE ÜBERTRAGEN WERDEN, WENN SIE DIE VIDEOS NICHT ABSPIELEN. ERST WENN SIE DIE VIDEOS
						ABSPIELEN, WERDEN DIE IN GENANNTEN DATEN ÜBERTRAGEN. AUF DIESE DATENÜBERTRAGUNG HABEN WIR
						KEINEN EINFLUSS. DURCH DEN BESUCH AUF DER WEBSITE ERHÄLT YOUTUBE DIE INFORMATION, DASS SIE DIE
						ENTSPRECHENDE UNTERSEITE UNSERER WEBSITE AUFGERUFEN HABEN. DIES ERFOLGT UNABHÄNGIG DAVON, OB
						YOUTUBE EIN NUTZERKONTO BEREITSTELLT, ÜBER DAS SIE EINGELOGGT SIND, ODER OB KEIN NUTZERKONTO
						BESTEHT. WENN SIE BEI GOOGLE EINGELOGGT SIND, WERDEN IHRE DATEN DIREKT IHREM KONTO ZUGEORDNET.
						WENN SIE DIE ZUORDNUNG MIT IHREM PROFIL BEI YOUTUBE NICHT WÜNSCHEN, MÜSSEN SIE SICH VOR
						AKTIVIERUNG DES BUTTONS AUSLOGGEN. YOUTUBE SPEICHERT IHRE DATEN ALS NUTZUNGSPROFILE UND NUTZT
						SIE FÜR ZWECKE DER WERBUNG, MARKTFORSCHUNG UND/ODER BEDARFSGERECHTEN GESTALTUNG SEINER
						WEBSITE. EINE SOLCHE AUSWERTUNG ERFOLGT INSBESONDERE (SELBST FÜR NICHT EINGELOGGTE NUTZER) ZUR
						ERBRINGUNG VON BEDARFSGERECHTER WERBUNG UND UM ANDERE NUTZER DES SOZIALEN NETZWERKS ÜBER IHRE
						AKTIVITÄTEN AUF UNSERER WEBSITE ZU INFORMIEREN. IHNEN STEHT EIN WIDERSPRUCHSRECHT ZU GEGEN DIE
						BILDUNG DIESER NUTZERPROFILE, WOBEI SIE SICH ZUR AUSÜBUNG DESSEN AN YOUTUBE RICHTEN MÜSSEN.
						WEITERE INFORMATIONEN ZU ZWECK UND UMFANG DER DATENERHEBUNG UND IHRER VERARBEITUNG DURCH
						YOUTUBE ERHALTEN SIE IN DER DATENSCHUTZERKLÄRUNG. DORT ERHALTEN SIE AUCH WEITERE INFORMATIONEN
						ZU IHREN RECHTEN UND EINSTELLUNGSMÖGLICHKEITEN ZUM SCHUTZE IHRER PRIVATSPHÄRE:
						WWW.GOOGLE.DE/INTL/DE/POLICIES/PRIVACY . GOOGLE VERARBEITET IHRE PERSONENBEZOGENEN DATEN AUCH
						IN DEN USA UND HAT SICH DEM EU-US-PRIVACY-SHIELD UNTERWORFEN,
						WWW.PRIVACYSHIELD.GOV/EU-US-FRAMEWORK.
						<br />
						<br />
						<Divider />
						<br />
						<strong>13. EINBINDUNG VON GOOGLE MAPS</strong>
						<br />
						AUF DIESER WEBSEITE NUTZEN WIR DAS ANGEBOT VON GOOGLE MAPS. DADURCH KÖNNEN WIR IHNEN
						INTERAKTIVE KARTEN DIREKT IN DER WEBSITE ANZEIGEN UND ERMÖGLICHEN IHNEN DIE KOMFORTABLE
						NUTZUNG DER KARTEN-FUNKTION. DURCH DEN BESUCH AUF DER WEBSITE ERHÄLT GOOGLE DIE INFORMATION,
						DASS SIE DIE ENTSPRECHENDE UNTERSEITE UNSERER WEBSITE AUFGERUFEN HABEN. ZUDEM WERDEN DIE UNTER
						ZIFFER IV DIESER ERKLÄRUNG GENANNTEN DATEN AN GOOGLE ÜBERMITTELT. DIES ERFOLGT UNABHÄNGIG
						DAVON, OB GOOGLE EIN NUTZERKONTO BEREITSTELLT, ÜBER DAS SIE EINGELOGGT SIND, ODER OB KEIN
						NUTZERKONTO BESTEHT. WENN SIE BEI GOOGLE EINGELOGGT SIND, WERDEN IHRE DATEN DIREKT IHREM KONTO
						ZUGEORDNET. WENN SIE DIE ZUORDNUNG MIT IHREM PROFIL BEI GOOGLE NICHT WÜNSCHEN, MÜSSEN SIE SICH
						VOR AKTIVIERUNG DES BUTTONS AUSLOGGEN. GOOGLE SPEICHERT IHRE DATEN ALS NUTZUNGSPROFILE UND
						NUTZT SIE FÜR ZWECKE DER WERBUNG, MARKTFORSCHUNG UND/ODER BEDARFSGERECHTEN GESTALTUNG SEINER
						WEBSITE. EINE SOLCHE AUSWERTUNG ERFOLGT INSBESONDERE (SELBST FÜR NICHT EINGELOGGTE NUTZER) ZUR
						ERBRINGUNG VON BEDARFSGERECHTER WERBUNG UND UM ANDERE NUTZER DES SOZIALEN NETZWERKS ÜBER IHRE
						AKTIVITÄTEN AUF UNSERER WEBSITE ZU INFORMIEREN. IHNEN STEHT EIN WIDERSPRUCHSRECHT ZU GEGEN DIE
						BILDUNG DIESER NUTZERPROFILE, WOBEI SIE SICH ZUR AUSÜBUNG DESSEN AN GOOGLE RICHTEN MÜSSEN.
						WEITERE INFORMATIONEN ZU ZWECK UND UMFANG DER DATENERHEBUNG UND IHRER VERARBEITUNG DURCH DEN
						PLUG-IN-ANBIETER ERHALTEN SIE IN DEN DATENSCHUTZERKLÄRUNGEN DES ANBIETERS. DORT ERHALTEN SIE
						AUCH WEITERE INFORMATIONEN ZU IHREN DIESBEZÜGLICHEN RECHTEN UND EINSTELLUNGSMÖGLICHKEITEN ZUM
						SCHUTZE IHRER PRIVATSPHÄRE: WWW.GOOGLE.DE/INTL/DE/POLICIES/PRIVACY . GOOGLE VERARBEITET IHRE
						PERSONENBEZOGENEN DATEN AUCH IN DEN USA UND HAT SICH DEM EU-US PRIVACY SHIELD UNTERWORFEN,
						WWW.PRIVACYSHIELD.GOV/EU-US-FRAMEWORK .
						<br />
						<br />
						<Divider />
						<br />
						<strong>14. Datensicherheit</strong>
						<br />
						Wir setzen zudem technische und organisatorische Sicherheitsmaßnahmen ein, um anfallende oder
						erhobene personenbezogene Daten zu schützen, insb. gegen zufällige oder vorsätzliche
						Manipulation, Verlust, Zerstörung oder gegen den Angriff unberechtigter Personen. Unsere
						Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend
						verbessert. Wir stellen Ihnen diverse Online-Formulare und Dienste bereit, mit denen Sie
						personenbezogene Daten an uns senden können. Diese Formulare sind gegen Einsichtnahme Dritter
						durch die Verwendung von TLS-Verschlüsselung geschützt. Die Daten, die Sie eingeben oder als
						Datei an uns übermitteln, können von uns gespeichert und nach Vereinbarung verarbeitet werden.
						Sofern die Nutzung und Verarbeitung der Einwilligung des Nutzers oder von Dritten bedarf, kann
						die Einwilligung jederzeit ohne Angaben von Gründen widerrufen werden. In diesem kann jedoch
						evtl. die Vertragserfüllung durch uns beeinträchtigt werden. Je nach Service können Sie zu
						verschiedenen Eingaben zur Identifizierung bzw. Verhinderung von Missbrauch aufgefordert
						werden: a) Zur Identifizierung bei der Anlieferung von Daten kann die Eingabe einer
						benutzerdefinierten Kennung oder anderweitigen geeigneten Authentifikation verlangt werden.
						Die Daten werden über SFTP oder HTTPS gegen Kenntnisnahme Dritter geschützt, sofern der Nutzer
						die von uns empfohlenen Wege der Datenübermittlung nutzt. b) Zur Verhinderung der Nutzung
						durch Maschinen können sogenannte CAPTCHAS verwendet werden, die Bilder oder Aufgaben
						enthalten, die von Computerskripten nicht verarbeitet werden können.
						<br />
						<br />
						<Divider />
						<br />
						<strong>15. Löschfristen</strong>
						<br />
						Wir speichern personenbezogene Daten jeweils nur so lange, bis der Zweck der Datenspeicherung
						entfällt, so lange keine gesetzlichen Aufbewahrungsfristen oder Verjährungsfristen
						möglicherweise der Rechtsverfolgung dienlichen Daten der Löschung entgegenstehen (in diesem
						Fall wird die Verarbeitung der Daten nach Art. 18 DSGVO eingeschränkt).
						<br />
						<br />
						<Divider />
					</div>
				</List>
			</Dialog>
		</div>
	);
}
