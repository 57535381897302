function Headerlinks({ stil }) {
	const handleScroll = id => {
		const element = document.getElementById(id);
		const headerOffset = 80;
		const elementPosition = element.getBoundingClientRect().top;
		const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

		window.scrollTo({
			top: offsetPosition,
			// behavior: 'smooth',
		});
	};

	const handleScrollTop = () => {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	};
	return (
		<>
			<li className={stil} onClick={handleScrollTop}>
				Home
			</li>
			<li className={stil} onClick={() => handleScroll('projekte-aktuell')}>
				Aktuelle Bauvorhaben
			</li>
			<li className={stil} onClick={() => handleScroll('projekte-abgeschlossen')}>
				Abgeschlossene Bauvorhaben
			</li>
			<li className={stil} onClick={() => handleScroll('videos')}>
				Videos
			</li>
			<li className={stil} onClick={() => handleScroll('kontakt')}>
				Kontakt
			</li>
		</>
	);
}

export default Headerlinks;
