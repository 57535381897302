import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import FormInputs from './FormInputs';

export default function EmailForm() {
	const [form, setForm] = useState({ name: '', email: '', message: '' });
	const [showFormErr, setShowFormErr] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState({ title: '', paragraph: '' });
	const [showCaptcha, setShowCaptcha] = useState(false);

	// console.log(form);

	const submitFormAndShowCaptcha = e => {
		e.preventDefault();
		setShowCaptcha(true);
	};

	const handleFormChange = e => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	const sendEmail = captchaValue => {
		console.log(captchaValue);
		if (form.name === '' || form.email === '' || form.message === '') {
			setShowFormErr(true);
			return;
		}

		const params = {
			...form,
			'g-recaptcha-response': captchaValue,
		};

		setFormSubmitted({ title: 'Nachricht wird gesendet...', paragraph: '' });
		emailjs.send('service_91y196e', 'template_lx3isbb', params, 'EqlQLPf2D1uL3j_GL').then(
			({ status }) => {
				if (status === 200) {
					setFormSubmitted({
						title: 'Vielen Dank für Ihre Nachricht!',
						paragraph: 'Wir werden uns schnellstmöglich bei Ihnen melden.',
					});
				} else {
					setFormSubmitted({
						title: 'Leider hat das nicht funktioniert ...',
						paragraph:
							'Versuchen Sie es bitte später erneut, oder kontaktieren Sie uns via Email oder Telefon.',
					});
				}
			},
			err => {
				// eslint-disable-next-line no-console
				console.log(err);
				setFormSubmitted({
					title: 'Leider hat das nicht funktioniert ...',
					paragraph:
						'Versuchen Sie es bitte später erneut, oder kontaktieren Sie uns via Email oder Telefon.',
				});
			}
		);
	};
	return formSubmitted.title === '' ? (
		<div className='text-xl'>
			{!showCaptcha ? (
				<form onSubmit={submitFormAndShowCaptcha}>
					<FormInputs form={form} handleFormChange={handleFormChange} />
					<div className='w-full flex justify-end items-center flex-col sm:flex-row mb-10'>
						{showFormErr ? (
							<p className='sm:mr-4 text-red-400'>
								Please fill in all three input boxes to send a message
							</p>
						) : null}
						<button className='text-black shadow-lg p-3' type='submit'>
							Abschicken
						</button>
					</div>
				</form>
			) : (
				<ReCAPTCHA sitekey='6LfEgv4gAAAAAAzUEl6FIv-9q2a0H8CT8QUkFCXW' onChange={sendEmail} />
			)}
		</div>
	) : (
		<div className='flex flex-col items-center'>
			<h3 className='text-2xl font-light text-black'>{formSubmitted.title}</h3>
			<p className='text-center'>{formSubmitted.paragraph}</p>
		</div>
	);
}
