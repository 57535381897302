import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { AiOutlineClose } from 'react-icons/ai';
import { Tooltip } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function Impressum({ open, setOpen }) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div className='font-darkerGrotesque'>
			<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
				<AppBar
					sx={{ position: 'relative' }}
					style={{
						background: '#fff',
						position: 'sticky',
						top: 0,
					}}>
					<Toolbar>
						<Tooltip title='Impressum schließen' placement='bottom-start'>
							<Typography
								sx={{ ml: 3, flex: 1 }}
								variant='h6'
								fontFamily='Darker Grotesque'
								component='div'
								color='black'
								className='cursor-pointer'
								onClick={() => setOpen(false)}>
								Impressum
							</Typography>
						</Tooltip>
						<AiOutlineClose
							onClick={handleClose}
							className='w-5 h-5 text-black cursor-pointer hover:text-gray-400 right-5'
						/>
					</Toolbar>
				</AppBar>
				<div>
					<List>
						<div style={{ margin: '50px 50px 50px 50px' }} className='font-darkerGrotesque text-xl'>
							<strong>Axel Maisenhelder</strong>
							<br />
							Architekt Diplomingenieur (FH)
							<br />
							Römerstrasse 19
							<br />
							D-74223 Flein
							<br />
							<br />
							<Divider />
							<br />
							<strong>Telefon:</strong> +49 7131 61 88 63-0 <br />
							<strong>Telefax:</strong> +49 7131 61 88 63-3 <br />
							<strong>E-Mail-Adresse:</strong>{' '}
							<a className='hover:text-gray-400' href='mailto:maisenhelder@heilbronn-architektur.de'>
								maisenhelder@heilbronn-architektur.de
							</a>{' '}
							<br />
							<br />
							<Divider />
							<br />
							<strong>Umsatzsteuer-Identifikationsnummer:</strong> DE 292 341 842 <br />
							<strong>Architektenkammer Baden-Württemberg:</strong> Listennr.: 37629 A. Maisenhelder <br />
							<br />
							<Divider />
							<br />
							<strong>Zuständige Kammer:</strong> Architektenkammer Baden Württemberg, Bundesrepublik
							Deutschland, Mitglieds-Nr. 37629
							<br />
							<strong>Berufsbezeichnung:</strong> Architekt
							<br />
							<br />
							<Divider />
							<br />
							<strong>Es gelten folgende berufsrechtliche Regelungen:</strong>
							<br />
							Berufsrechtliche Regelungen Baukammergesetz Baden-Württemberg Regelungen einsehbar unter:{' '}
							<br />
							<a
								className='hover:text-gray-400'
								href='http://www.akbw.de/download/architektengesetz.pdf'
								target='_blank'>
								http://www.akbw.de/download/architektengesetz.pdf
							</a>
							<br />
							<a
								className='hover:text-gray-400'
								href='http://www.akbw.de/download/berufsordnung.pdf'
								target='_blank'>
								http://www.akbw.de/download/berufsordnung.pdf
							</a>
							<br />
							<br />
							<Divider />
							<br />
							<strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong> Axel Maisenhelder
							<br />
							<br />
							<Divider />
							<br />
							<strong>Haftungshinweise:</strong> <br />
							Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte
							externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
							verantwortlich.
							<br />
							<br />
							<Divider />
							<br />
							<strong>Webdesign, Online-Marketing & SEO:</strong> <br />
							<a href='https://regiobozz.de' target='_blank' noreferrer className='font-bold'>
								regioBOZZ.de
							</a>
							<br />
						</div>
					</List>
				</div>
			</Dialog>
		</div>
	);
}
