import profil from "../../images/profil.jpg";
import maisenhelder from "../../images/maisenhelder.jpg";

export default function Monogram() {
	return (
		<div className="bg-gray-200 bg-opacity-70 relative w-full h-24 lg:h-28 top-3/4 flex flex-row justify-start items-center px-2 2xl:px-16 font-darkerGrotesque">
			<div className="w-56">
				<img src={maisenhelder} className="h-full w-fit rounded-md" />
			</div>
			<div>
				<h1 className="text-black text-2xl lg:text-4xl font-bold ml-3">Axel Maisenhelder</h1>
				<h2 className="text-black text-xl lg:text-3xl ml-3">Architekt Dipl.Ing.(FH) - SIGEKO</h2>
			</div>
		</div>
	);
}
