import React, { useState, useEffect } from "react";
import { urlFor, client } from "../client";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Headline from "../components/Headline";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import TextBauvorhaben from "../components/bauvorhaben/TextBauvorhaben";
import DialogPicture from "../components/bauvorhaben/DialogPicture";
import { IKImage, IKContext } from "imagekitio-react";

function AktuelleBauvorhaben() {
	const [images, setImages] = useState([]);
	const [currentImage, setCurrentImage] = useState("");
	const [openPicture, setOpenPicture] = useState(false);

	useEffect(() => {
		const query = '*[_type == "gallery"]';
		client.fetch(query).then((data) => setImages(data));
	}, []);

	function useWindowSize() {
		const [windowSize, setWindowSize] = useState({
			width: undefined,
			height: undefined,
		});
		useEffect(() => {
			// Handler to call on window resize
			function handleResize() {
				// Set window width/height to state
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}
			// Add event listener
			window.addEventListener("resize", handleResize);
			// Call handler right away so state gets updated with initial window size
			handleResize();
			// Remove event listener on cleanup
			return () => window.removeEventListener("resize", handleResize);
		}, []); // Empty array ensures that effect is only run on mount
		return windowSize;
	}

	const size = useWindowSize();
	// console.log(size);

	const openLarge = (image) => {
		setCurrentImage(image);
		setOpenPicture(true);
	};

	const textDiv = "border border-black w-full p-4";
	const h1Div = "text-2xl text-center font-bold mb-1";
	const pDiv = "mt-2 text-center";

	return (
		<>
			<IKContext urlEndpoint="https://ik.imagekit.io/d6wyu834t9">
				<div className="relative max-w-8xl mx-auto min-h-fit mt-12 font-darkerGrotesque" id="projekte-aktuell">
					<Headline title="Aktuelle Bauvorhaben" />
					{images.map((i) => (
						<div className="relative">
							{/* <h1 className='text-white text-xl md:text-2xl lowercase absolute z-10 md:bottom-5 md:left-5 bottom-2 left-5 mix-blend-difference'>
								{i.name}
							</h1> */}
							<Swiper
								modules={[Navigation]}
								navigation
								// speed={800}
								slidesPerView={
									size.width < 450 ? 1 : size.width >= 1000 && size.width < 1400 ? 3 : size.width >= 1400 ? 4 : 2
								}
								spaceBetween={5}
								loop
								className="h-80 w-full my-2 z-0">
								{i.imagesGallery.map((ig) => (
									<SwiperSlide className="" key={ig._key}>
										<IKImage
											src={urlFor(ig)}
											transformation={{
												height: "200",
												width: "200",
											}}
											className="h-80 w-full object-cover grayscale hover:grayscale-0 cursor-pointer"
											alt={i.name + "_" + ig._key}
											loading="lazy"
											onClick={() => openLarge(urlFor(ig))}
										/>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					))}
					<TextBauvorhaben />
				</div>
				<DialogPicture open={openPicture} setOpen={setOpenPicture} image={currentImage} />
			</IKContext>
		</>
	);
}

export default AktuelleBauvorhaben;
