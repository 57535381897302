import React, { useEffect, useState } from 'react';
import bewertung from '../images/bewertung.png';
import bewertungHead from '../images/bewertung-head.png';
import { home1, home2, home3, home4, home6 } from '../images/home';
import Monogram from './header/Monogram';
import Navbar from './header/Navbar';
import NavbarMenu from './header/NavbarMenu';

const bgImages = [home1, home2, home3, home4, home6];

function Header() {
	// TODO: Header Change
	const [headerImg, setHeaderImg] = useState(bgImages[0]);
	const [headerCount, setHeaderCount] = useState(0);
	const [nav, setNav] = useState(false);
	const [shadow, setShadow] = useState(false);

	const handleNav = () => {
		setNav(!nav);
	};

	useEffect(() => {
		const handleShadow = () => {
			if (window.scrollY >= 90) {
				setShadow(true);
			} else {
				setShadow(false);
			}
		};
		window.addEventListener('scroll', handleShadow);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (headerCount !== bgImages.length) {
				setHeaderCount(headerCount + 1);
				setHeaderImg(bgImages[headerCount]);
			} else {
				setHeaderCount(0);
				setHeaderImg(bgImages[0]);
			}
		}, 5000);
	}, [headerCount]);

	// const handleScrollTop = () => {
	// 	document.body.scrollTop = 0; // For Safari
	// 	document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	// };

	// const iconClass = 'hidden sm:flex cursor-pointer hover:scale-125 hover:text-gray-400 h-4 w-4'; //old version
	const iconClass = 'hidden sm:flex cursor-pointer hover:scale-125 h-4 w-4';
	const iconClassMenu = 'flex cursor-pointer hover:scale-125 hover:text-gray-400 h-4 w-4 text-black';

	return (
		<div
			className='flex w-screen h-screen bg-cover transition duration-300 ease-linear'
			style={{ backgroundImage: `url(${headerImg})`, backgroundPositionX: 'center' }}>
			<Monogram />
			{/* <div className=''>
				<img
					src={bewertungHead}
					className='absolute hidden sm:block sm:h-40 rounded-md right-16 top-[73%]'
				/>
			</div> */}
			<div
				className={
					shadow
						? 'fixed w-full h-14 shadow-xl z-[100] bg-white transition-all duration-200 ease-in-out '
						: 'fixed w-full h-20 z-[100] transition-all duration-200 ease-in-out text-white'
				}>
				<Navbar handleNav={handleNav} shadow={shadow} />
				<NavbarMenu handleNav={handleNav} nav={nav} />
			</div>
		</div>
	);
}

export default Header;
