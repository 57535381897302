import Headerlinks from './Headerlinks';
import SocialMedia from './SocialMedia';
import { AiOutlineClose } from 'react-icons/ai';
import logo from '../../images/logo-am.png';

export default function NavbarMenu({ handleNav, nav }) {
	const iconClassMenu = 'flex cursor-pointer hover:scale-125 h-4 w-4 text-black';
	return (
		<div className={nav ? 'fixed left-0 top-0 w-full h-screen bg-black/70' : ''}>
			<div
				className={
					nav
						? 'fixed left-0 top-0 w-[75%] sm:w-[60%] md:w-[45%] bg-[#ecf0f3] p-10 ease-in duration-500 h-screen'
						: 'fixed left-[-100%] top-0 p-10 ease-in duration-500'
				}>
				<div className=''>
					<div className='flex w-full items-center justify-between'>
						<div className='flex mt-3'>
							<img src={logo} alt='Logo' className='h-6 rounded-sm mr-3 hidden sm:inline' />
							<span className='text-black font-bold'>AXELMAISENHELDER</span>
						</div>
						<div
							className='rounded-full hover:shadow-lg hover:shadow-gray-400 p-3 cursor-pointer absolute right-2 top-2'
							onClick={handleNav}>
							<AiOutlineClose size={15} className='text-black' />
						</div>
					</div>
					<div className='border-b border-gray-300 my-2 text-black'>
						<p className='w-[85%] md:w-[90%] py-3 font-bold'>Architekt Dipl.Ing.(FH)</p>
						<p className='mb-3'>Das Bewährte wieder tun!</p>
					</div>
				</div>
				<div className='py-2 flex flex-col' onClick={handleNav}>
					<div className='border-b border-gray-300 mb-6'>
						<ul className='uppercase'>
							<Headerlinks stil='py-4 text-sm cursor-pointer hover:text-gray-400 text-black' />
						</ul>
						<hr />
					</div>
					<div className='flex space-x-4 mt-2'>
						<SocialMedia iconClass={iconClassMenu} />
					</div>
				</div>
			</div>
		</div>
	);
}
